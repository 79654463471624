import { Indicator } from "@Components/Indicator";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { chatrooms } from "recoil/atoms/chatrooms";
import { user } from "recoil/atoms/user";

export const RepSidebar = (props) => {
  const [data, setUserData] = useRecoilState(user);
  const [chatRoom, setChatRoom] = useRecoilState(chatrooms);
  const [showLogoutConfirmation, setshowLogoutConfirmation] = useState(false);

  const audio = new Audio(process.env.PUBLIC_URL + "/audio/notification.mp3");

  const isNewMessage = chatRoom.chatrooms.some(
    (chatroom) =>
      chatroom.isNewMessage && chatroom.isNewMessage.includes(data?.user?._id)
  );

  const navigate = useNavigate();
  const location = useLocation();

  const site = location.pathname.split("/")[1];

  const handleNavigation = (path) => {
    navigate("/" + path);
  };

  // useEffect(() => {
  //   if (isNewMessage) {
  //     // Play notification sound
  //     audio.play().catch((error) => {
  //       console.error("Audio playback error:", error);
  //     });
  //   }
  // }, [audio, isNewMessage]);

  return (
    <div className="col-lg-2 ps-0 dn">
      <div className="bg-black vh-100 p-sticky">
        <img src="imgs/logo.png" className="img-fluid mt-40 p-20" alt="" />
        <ul className="list-unstyled mt-40">
          {[
            {
              name: "Job Market",
              icon: "fa-solid fa-user-group",
              path: "jobmarket",
              svg: (
                <svg
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 16C10.5 16 9 16 9 14.6667C9 13.3333 10.5 9.33333 16.5 9.33333C22.5 9.33333 24 13.3333 24 14.6667C24 16 22.5 16 22.5 16H10.5ZM16.5 8C17.6935 8 18.8381 7.57857 19.682 6.82843C20.5259 6.07828 21 5.06087 21 4C21 2.93913 20.5259 1.92172 19.682 1.17157C18.8381 0.421427 17.6935 0 16.5 0C15.3065 0 14.1619 0.421427 13.318 1.17157C12.4741 1.92172 12 2.93913 12 4C12 5.06087 12.4741 6.07828 13.318 6.82843C14.1619 7.57857 15.3065 8 16.5 8ZM7.824 16C7.60163 15.5838 7.49074 15.1274 7.5 14.6667C7.5 12.86 8.52 11 10.404 9.70667C9.46364 9.44911 8.48392 9.32316 7.5 9.33333C1.5 9.33333 0 13.3333 0 14.6667C0 16 1.5 16 1.5 16H7.824ZM6.75 8C7.74456 8 8.69839 7.64881 9.40165 7.02369C10.1049 6.39857 10.5 5.55072 10.5 4.66667C10.5 3.78261 10.1049 2.93477 9.40165 2.30964C8.69839 1.68452 7.74456 1.33333 6.75 1.33333C5.75544 1.33333 4.80161 1.68452 4.09835 2.30964C3.39509 2.93477 3 3.78261 3 4.66667C3 5.55072 3.39509 6.39857 4.09835 7.02369C4.80161 7.64881 5.75544 8 6.75 8Z"
                    fill="#BDBDBF"
                  />
                </svg>
              ),
            },
            {
              name: "Messages",
              path: "messages",
              svg: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={22}
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <path
                    d="M6.96 13.6889V6.11111H2.4C1.08 6.11111 0 7.21111 0 8.55556V15.8889C0 17.2333 1.08 18.3333 2.4 18.3333H3.6V22L7.2 18.3333H13.2C14.52 18.3333 15.6 17.2333 15.6 15.8889V13.6644C15.5211 13.6817 15.4407 13.6903 15.36 13.6901H6.96V13.6889ZM21.6 0H10.8C9.48 0 8.4 1.1 8.4 2.44444V12.2222H16.8L20.4 15.8889V12.2222H21.6C22.92 12.2222 24 11.1234 24 9.77778V2.44444C24 1.1 22.92 0 21.6 0Z"
                    fill="#BDBDBF"
                  />
                </svg>
              ),
            },
            {
              name: "Notifications",
              path: "notification",
              svg: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={22}
                  viewBox="0 0 24 30"
                  fill="none"
                >
                  <path
                    d="M0 25.5V22.5H3V12C3 9.925 3.625 8.081 4.875 6.468C6.125 4.855 7.75 3.799 9.75 3.3V0H14.25V3.3C16.25 3.8 17.875 4.8565 19.125 6.4695C20.375 8.0825 21 9.926 21 12V22.5H24V25.5H0ZM12 30C11.175 30 10.4685 29.706 9.8805 29.118C9.2925 28.53 8.999 27.824 9 27H15C15 27.825 14.706 28.5315 14.118 29.1195C13.53 29.7075 12.824 30.001 12 30Z"
                    fill="#BDBDBF"
                  />
                </svg>
              ),
            },
            {
              name: "Verification",
              path: "verification",
              svg: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={19}
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M8.29091 23L6.21818 19.4952L2.29091 18.619L2.67273 14.5667L0 11.5L2.67273 8.43333L2.29091 4.38095L6.21818 3.50476L8.29091 0L12 1.5881L15.7091 0L17.7818 3.50476L21.7091 4.38095L21.3273 8.43333L24 11.5L21.3273 14.5667L21.7091 18.619L17.7818 19.4952L15.7091 23L12 21.4119L8.29091 23ZM10.8545 15.3881L17.0182 9.2L15.4909 7.6119L10.8545 12.2667L8.50909 9.96667L6.98182 11.5L10.8545 15.3881Z"
                    fill="#BDBDBF"
                  />
                </svg>
              ),
            },
            {
              name: "Account",
              path: "account",
              svg: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={17}
                  viewBox="0 0 24 17"
                  fill="none"
                >
                  <path
                    d="M15.2727 10.625C12.36 10.625 6.54545 12.0381 6.54545 14.875V17H24V14.875C24 12.0381 18.1855 10.625 15.2727 10.625ZM15.2727 8.5C16.43 8.5 17.5399 8.05223 18.3583 7.2552C19.1766 6.45817 19.6364 5.37717 19.6364 4.25C19.6364 3.12283 19.1766 2.04183 18.3583 1.2448C17.5399 0.447766 16.43 0 15.2727 0C14.1154 0 13.0055 0.447766 12.1872 1.2448C11.3688 2.04183 10.9091 3.12283 10.9091 4.25C10.9091 5.37717 11.3688 6.45817 12.1872 7.2552C13.0055 8.05223 14.1154 8.5 15.2727 8.5ZM4.36364 9.86L7.03636 11.4431L6.32727 8.4575L8.72727 6.46L5.57455 6.19438L4.36364 3.38937L3.13091 6.19438L0 6.46L2.37818 8.4575L1.63636 11.4431L4.36364 9.86Z"
                    fill="#BDBDBF"
                  />
                </svg>
              ),
            },
            {
              name: "Logout",
              onClick: () => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setshowLogoutConfirmation(true);
              },
              svg: (
                <svg
                  fill="#BDBDBF"
                  height="24px"
                  width="17px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384.971 384.971"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g id="Sign_Out">
                        <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z"></path>{" "}
                        <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"></path>{" "}
                      </g>
                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                    </g>
                  </g>
                </svg>
              ),
            },
          ].map(({ icon, name, path, svg, onClick }, index) => (
            <li key={index}>
              <button
                onClick={() => {
                  if (onClick) {
                    onClick();
                  } else handleNavigation(path);
                }}
                type="button"
                className={site === path ? "active" : "inactive"}
              >
                <ul
                  className="list-inline"
                  style={name === "Logout" ? { paddingLeft: "10px" } : {}}
                >
                  <li className="list-inline-item">
                    {svg ? svg : <i className={icon} />}
                  </li>
                  <li className="list-inline-item fs-16">{name}</li>
                  <li
                    className="list-inline-item"
                    style={{ marginBottom: "10px" }}
                  >
                    {name === "Messages" && isNewMessage && <Indicator />}
                  </li>
                </ul>
              </button>
            </li>
          ))}
        </ul>
      </div>
      {showLogoutConfirmation && (
          <div
            className="modal fade show w-75 logout-popup"
            style={{
              display: "block",
              position: "absolute",
              top: "100px",
              left: "0px",
              right: "0px",
              margin: "auto",
            }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Logout Confirmation</h5>
                  <button
                    type="button"
                    className="btn-close text-white"
                    onClick={() => setshowLogoutConfirmation(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to logout?
                </div>
                <div className="modal-footer">
                  <div className="d-flex ">
                    <button
                      type="button"
                      className="btn btn-danger me-3"
                      onClick={() => {
                        setUserData((prevState) => ({}));
                        sessionStorage.removeItem("refresh");
                        sessionStorage.removeItem("refresh_exp");
                        navigate("/");
                      }}
                    >
                      Logout
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setshowLogoutConfirmation(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
