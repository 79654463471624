
import React, { useEffect, useRef, useState } from "react";
import { SellerSidebar } from "@Components/Sidebar/SellerSidebar";
import { SellerMobileSidebar } from "@Components/Sidebar/SellerSidebar/sellerMobileSidebar";
import { api } from "api/seller";
import { useRecoilState } from "recoil";
import { user } from "recoil/atoms/user";
import { toast } from "react-toastify";
import { sidebar } from "recoil/atoms/sidebar";
import withClearLocalStorage from "@Components/hoc";
import { useNavigate, useParams } from "react-router-dom";
import { RepSidebar } from "@Components/Sidebar/RepSidebar";
import { chatrooms } from "recoil/atoms/chatrooms";
import { Chatapi } from "api/chat";

const BusinessProfileInfo = () => {

    const {id} = useParams()
    const navigate=useNavigate()
 
  const [userData, setUserData] = useRecoilState(user);
  
  const [sellerInfo, setSellerInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [chatroom, setChatRooms] = useRecoilState(chatrooms);
  
  

//   const [formData, setFormData] = useState({
//     email: "",
//     companyName: "",
//     phone: "",
//     cancelSubscriptionEmail: "",
//     contactSupportEmail: "",
//     password: "",
//     profileImage: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (userData?.tokens?.access?.token) {
//       try {
//         setLoading(true);
//         await api.addSettingInfo(formData, userData?.tokens?.access?.token);
//         // window.location.reload();
//         toast.success("Data saved successfully");
//         setTimeout(()=>{
//           window.location.reload();

//         },2000)
        
//       } catch (error) {
//         setLoading(false);
//         toast.error(error?.response?.data?.message || "Error saving data");
//         console.error("There was a problem with the fetch operation:", error);
//       }
//     }
//   };

  useEffect(() => {
    const getInfo = async () => {
      if (!userData?.tokens?.access?.token) return;
      try {
        const response = await api.getSellerInfo(id,userData?.tokens?.access?.token);
        setSellerInfo(response.data);
        // setFormData((prevUser) => ({
        //   ...prevUser,
        //   companyName: response?.data?.companyName,
        //   email: response?.data?.email,
        //   phone: response?.data?.phone,
        //   cancelSubscriptionEmail: response?.data?.cancelSubscriptionEmail,
        //   contactSupportEmail: response?.data?.contactSupportEmail,
        //   profileImage: response?.data?.profileImage,
        // }));
      } catch (error) {
        console.log({ error });
      }
    };

    getInfo();
  }, [userData?.tokens?.access?.token,id]);

//   const handleIntroVideoChange = async (e) => {
//     const file = e.target.files[0];

//     try {
//       if (!file) return toast.error("Please select a video first");
//       if (!file.type.startsWith("video/")) {
//         return toast.error("Please select a video file");
//       }
//       setLoading(true);
//       const formData = new FormData();
//       formData.append(
//         "file",
//         file,
//         new Date().toDateString() + `into_${userData?.user?.fullName}`
//       );

//       const { data } = await api.saveIntro(
//         formData,
//         userData?.tokens?.access?.token
//       );
//       setUserData((prevState) => ({
//         ...prevState,
//         user: {
//           ...data,
//         },
//       }));
//       window.location.reload();
//     } catch (err) {
//       toast.error(err?.response?.data?.message || "Error uploading video");
//       setLoading(false);
//       console.log(err);
//     }
//   };

//   const handleProfilePicChange = async (e) => {
//     const file = e.target.files[0];
//     if (!file) return toast.error("Please select an image");

//     try {
//       setLoading(true);
//       const formData = new FormData();
//       formData.append("file", file);

//       // Replace 'api.saveProfilePic' with your actual API function for image upload
//       const { data } = await api.saveImage(
//         formData,
//         userData?.tokens?.access?.token
//       );
//       // Update the seller or user data with the new profile picture URL
//       // Assuming 'data.profilePicUrl' contains the URL of the uploaded image
//       setUserData((prevState) => ({
//         ...prevState,
//         user: {
//           ...prevState.user,
//           profileImage: data.profileImage,
//         },
//       }));
//       setFlag(!flag)
//       toast.success("Profile picture updated successfully");
//     } catch (err) {
//       toast.error("Error uploading image");
//       console.error(err);
//     } finally {
//       setLoading(false);
//       // window.location.reload();
//     }
//   };

//   const handlesupport = async () => {
//     let isvalid = true;

//     if (!title) {
//       settitleerr("Required*");
//       isvalid = false;
//     }
//     if (!description) {
//       setdescriptionerr("Required*");
//       isvalid = false;
//     }
//     if (isvalid) {
//       try {
//         // if (!params.token) throw new Error("Invalid token");
//         // Call your API function to reset the password
//         await api.support(userData?.tokens?.access?.token, {
//           title: title,
//           description: description,
//         });
//         settitle("");
//         setdescription("");
//         toast.success("Message sent successfully");

//         // toast.success("Password successfully reset");
//         // window.location.href = "/"
//       } catch (error) {
//         console.log({ error });
//         toast.error(error?.response?.data?.message);
//       }
//     }
//   };

console.log(userData?.user?._id && id)
console.log(sellerInfo)



const sendemessage = async (userData, id) => {
    console.log(userData,"data")
    console.log(id,"id")
    const addChatroom = () => {
      if (userData?.user?._id && id) {
        Chatapi.addRoom(
          {
            members: [userData?.user?._id, id],
            lastMessageTime: new Date(),
            initiator: userData?.user?._id,
          },
          userData?.tokens?.access?.token
        ).then((docRef) => {
          // console.log(docRef.id,"decref")
          setChatRooms((prevState) => ({
            ...prevState,
            selectedRoom: docRef.id,
          }));
          navigate("/messages");
        });
      }
    };

    var chatroom = await Chatapi.getAllChats(
      {},
      userData?.tokens?.access?.token
    );

    var chatroomdataexist = await chatroom?.data?.userallchat;

    if (chatroomdataexist?.length > 0) {
      var chatroomdataexistval = await chatroomdataexist?.find((chatroom) =>{
        
      return  chatroom.members.some((memberobject) =>{
          if(memberobject.id?.toString() == id?.toString()){
          
            // let activechat = memberobject.id
            // sessionStorage.setItem("activechat",memberobject.id)
            return memberobject
          }

        } )
      }
      );
      
      let activechat =  chatroomdataexistval?._id

      chatroomdataexistval && localStorage.setItem("activechat",activechat)

      if (chatroomdataexistval) {
        sessionStorage.setItem("activechat",chatroomdataexistval)
       
        setChatRooms((prevState) => ({
          ...prevState,
          selectedRoom: chatroomdataexistval?._id,
        }));

        navigate("/messages");
      } else {
        addChatroom();
      }
    } else {
       addChatroom();
    }
  };


  return (
    <div>
      <div className="bg-greyish">
        <div className="container-fluid">
          <div className="row">
            {/*               mobile view*/}
            <div
            //   onClick={() => {
            //     setOpenSideBar((prevState) => ({
            //       ...prevState,
            //       sidebar: true,
            //     }));
            //   }}
              className="col-lg-12 dn-1"
            >
              <a className="btn btn-primary">&gt;</a>
            </div>
            {/*               end of mobile view*/}
            <RepSidebar />
            <div className="col-lg-10">
              <div className="row mt-60">
                <div className="col-lg-12">
                  <h4 className="fs-26 fw-700 text-white">Bussiness Profile</h4>
                  <h6 className="fs-16 text-white">
                    {/* Quickly manage your account &amp; settings here! */}
                  </h6>
                </div>
              </div>
              <div className="row mt-20 mb-5" style={{ position: "relative" }}>
                {loading && (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "80vh",
                      zIndex: "1",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "rgba(0,0,0,0.2)",
                    }}
                  >
                    <div class="loader"></div>
                  </div>
                )}
                <div className="col-lg-6">
                  <div className="job-box p-60">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h1 className="text-white fs-24 fw-700">{ sellerInfo?.fullName}</h1>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "100%",
                            }}
                            src={sellerInfo?.profileImage}
                            alt=""
                            className="img-fluid mt-20"
                          />
                          <input
                            type="file"
                            // ref={profileImageRef}
                            // onChange={handleProfilePicChange}
                            style={{ display: "none" }}
                            accept="image/*"
                          />
                          {/* <button
                            // onClick={() => profileImageRef.current.click()}
                            className="btn btn-danger w-50 center sm-w-100 mt-20"
                          >
                            Change Profile Picture
                          </button> */}
                        </div>
                        <form 
                        // onSubmit={handleSubmit} 
                        className="mt-20">
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control h-10"
                              id="exampleInputName"
                              aria-describedby="NameHelp"
                              placeholder="Company Name"
                              name="companyName"
                              value={sellerInfo?.companyName}
                            //   onChange={handleChange}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control h-10"
                              id="exampleInputName"
                              aria-describedby="NameHelp"
                              placeholder="Email"
                              name="email"
                              value={sellerInfo?.email}
                            //   onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control h-10"
                              id="exampleInputnumber"
                              aria-describedby="NameHelp"
                              placeholder="Description"
                              name="phone"
                              value={sellerInfo?.description}
                            //   onChange={handleChange}
                            />
                          </div>

                          {/* <div className="mb-3">
                            <input
                              type="password"
                              className="form-control h-10"
                              id="exampleInputnumber"
                              aria-describedby="NameHelp"
                              placeholder="Enter Current Password"
                              name="currentPassword"
                            //   value={formData.currentPassword}
                            //   onChange={handleChange}
                            />
                          </div> */}
                          {/* <div className="mb-3">
                            <input
                              type="password"
                              className="form-control h-10"
                              id="exampleInputnumber"
                              aria-describedby="NameHelp"
                              placeholder="Enter New Password"
                              name="password"
                            //   value={formData.password}
                            //   onChange={handleChange}
                            />
                          </div> */}
                          {/* <p className="text-grey fs-16 mt-20 center text-start">
                            Forgot Password?
                            <span>
                              <a href="#" className="text-grey">
                                {" "}
                                Send Reset To Email
                              </a>
                            </span>
                          </p> */}
                          <button
                            type="button"
                            className="btn btn-danger w-100 center sm-w-100 "
                            style={{ cursor: "pointer" }}
                                onClick={() => {
                                  sendemessage(userData, sellerInfo?.id);
                                }}
                          >
                            Send Message
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="row mt-3">
                      {/* <div className="col-lg-12">
                        <div className="mb-3 position-relative">
                          <a href="mailto:RepConnectOfficial@gmail.com">
                            <button type="button" className="danger-button">
                              Cancel Subscription
                            </button>{" "}
                          </a>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6">
                        <div className="mb-3 position-relative">
                          <a href="mailto:RepConnectOfficial@gmail.com">
                            <button type="button" className="danger-button">
                              Contact Us
                            </button>{" "}
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 mt-31">
                  <div className="job-box p-60">
                    <div className="row">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            width: "350px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="col-lg-12 text-center"
                        >
                          <h1 className="text-white fs-26 fw-700">
                            Company Intro
                          </h1>
                          {sellerInfo?.introUrl && (
                            <div
                              className="mt-20"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "300px", height: "300px" }}>
                                <video
                                  style={{ width: "100%", height: "100%" }}
                                  controls
                                  autoPlay
                                >
                                  <source
                                    src={`${
                                      sellerInfo?.introUrl
                                    }?v=${Date.now()}`}
                                  ></source>
                                </video>
                              </div>
                            </div>
                          )}

                          <label
                            htmlFor="introVideo"
                            className="fs-18 text-decoration-underline mt-20"
                          >
                            {/* <div
                              style={{ width: "200px" }}
                              className="btn btn-outline-danger center sm-w-100 mt-40"
                            >
                              Change Video
                            </div> */}
                          </label>
                          <input
                            type="file"
                            id="introVideo"
                            name="introVideo"
                            // onChange={handleIntroVideoChange}
                            className="form-control mt-2"
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
{/* 
                <div className="col-lg-6">
                  <div className="job-box p-60 mt-4">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h1 className="text-white fs-24 fw-700 mb-4">
                          Contact Us
                        </h1>{" "}
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control h-10"
                            id="exampleInputName"
                            aria-describedby="NameHelp"
                            placeholder="Title"
                            name="title"
                            value={title}
                            onChange={(e) => {
                              settitle(e.target.value);
                              settitleerr("")
                            }}
                          />
                           <div className="text-danger">{titleerr}</div>
                        </div>
                        <div className="mb-3">
                          <textarea
                            rows={3}
                            className="form-control "
                            id="exampleInputName"
                            aria-describedby="NameHelp"
                            placeholder="Description"
                            name="description"
                            value={description}
                            onChange={(e) => {
                              setdescription(e.target.value);
                              setdescriptionerr("");
                            }}
                          />
                            <div className="text-danger">{descriptionerr}</div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-danger w-100 center sm-w-100 mt-3"
                          onClick={handlesupport}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    offcanvas*/}
      {/* {openSideBar.sidebar && <SellerMobileSidebar />} */}

      {/*    end of offcanvas*/}
    </div>
  );
};

export default BusinessProfileInfo
