import Router from "@Routes/index";
import { useRecoilState } from "recoil";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { api } from "api/auth";
import { api as userApi } from "api/user";
import { user } from "recoil/atoms/user";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db, requestForToken } from "util/firebase";
import { chatrooms } from "recoil/atoms/chatrooms";
import { getUserPathAppJs } from "util/getRoute";

const App = () => {

  const [userData, setUserData] = useRecoilState(user);
  const [chRoom, setChRoom] = useRecoilState(chatrooms);

  useEffect(() => {
    requestForToken().then(function(data){
      console.log(data);
    })
    // console.log("suspend")
   
  }, []);

  
  // useEffect(async()=>{

  //     try {
  //       console.log("hello")
  //       const { data } = await api.getUserData({ accessToken: sessionStorage.getItem("refresh") });
        
       
  //     } catch (error) {
  //       console.log(error?.response?.data?.code,"jjjjjjjjjjjjjjjjjjjjj")
  //       if(error?.response?.data?.code == "401"){
  //         //  if(sessionStorage.getItem("refresh")){
  //         //    window.location.href="/login"
  //         //  }
  //         sessionStorage.clear();
          
  //       }
  //     }
  

  // },[])



  useEffect(() => {
    // Fetch access token using refresh token
    async function fetchAccessToken() {
      try {
        const refreshToken = sessionStorage.getItem("refresh");
      
        if (!refreshToken) {
          sessionStorage.clear();
          // if (!["/", "/login", "/forgotPassword"].includes(window.location.pathname)) {
          //   window.location.href = "/";
          // }
          return null;
        }

        const response = await api.fetchAccessToken({ refreshToken });
        return response?.data;
      } catch (error) {
        return null;
      }
    }

    // Fetch user data using access token
    async function fetchUserData(accessToken) {
      try {
        // console.log("hello")
        const { data } = await api.getUserData({ accessToken });
        setUserData((prevState) => ({
          ...prevState,
          user: {
            ...data,
          },
        }));
        if (window.location.pathname === "/") {
          if (data?.role === "rep") {
            window.location.href = "/jobmarket";
          } else {
            window.location.href = "/repmarket";
          }
        } else {
          getUserPathAppJs(data);
        }
      } catch (error) {
        // console.log(error,"bann")
        if(error?.response?.status == "403"){

          // window.location.href="/"
          
        }
      }
    }

    async function fetchData() {
      const tokens = await fetchAccessToken();
      if (!tokens) {
        return;
      }
      setUserData((prevState) => ({
        ...prevState,
        tokens,
      }));
      if (tokens?.refresh) {
        sessionStorage.setItem("refresh", tokens?.refresh?.token);
        sessionStorage.setItem("refresh_exp", tokens?.refresh?.expires);
      }
      if (tokens?.access?.token) {
        fetchUserData(tokens.access.token);
      }
    }

    fetchData();
  }, [setUserData]);


  useEffect(() => {
    if (!userData?.user?._id) return;

    const chatRoomsQuery = query(
      collection(db, "chatrooms"),
      where("members", "array-contains", userData.user._id),
      orderBy("lastMessageTime", "desc")
    );



    const unsub = onSnapshot(chatRoomsQuery, async (snapshot) => {
      
      const chatrooms = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const allMemberIds = [...new Set(chatrooms.flatMap((room) => room.members))];
      const { data } = await userApi.getUsers({ ids: allMemberIds });

      if (data?.length) {
        const mappedMembers = Object.fromEntries(data.map((member) => [member._id, member]));
        setChRoom((prev) => ({ ...prev, chatrooms, membersMap: mappedMembers }));
      } else {
        setChRoom((prev) => ({ ...prev, chatrooms: [], selectedRoom: "", membersMap: {} }));
      }
    });


    // console.log(chatRoomsQuery,"chatRoomsQuery testing")

    return unsub;
  }, [setChRoom, userData?.user?._id]);

  // console.log(userData?.tokens?.access?.token)


  // useEffect(()=>{
  //   console.log("first")
  // },[])




  return (
    <div>
      <ToastContainer />
      <Router />
    </div>
  );
};

export default App;
