export function getUserPath(user) {

  
  
  // Check if user.role is empty or 'user'
  if (!user.role || user.role === "user") {
    return "chooseaccount";
  }
  // Check for empty introUrl
  if (!user.introUrl) {
    if (user.role === "rep") {
      return "introduction";
    }
    if (user.role === "seller") {
      return "introductionbussiness";
    }
  }

  // Check for niche if introUrl is provided
  if (!user.isProfileCompleted) {
    if (user.role === "rep") {
      return "repinfo";
    }
    if (user.role === "seller") {
      return "bussinessinfo";
    }
  }

  if (user.role === "rep") {
    return "jobmarket";
  }
  if (user.role === "seller") {
    return "repmarket";
  }

  return ""; // Default return, can be adjusted based on other potential cases
}

export function getUserPathAppJs(user) {
  const path = window.location.pathname;

  // // Check if user.role is empty or 'user'
  // if ((!user.role || user.role === "user") && path !== "/chooseaccount") {
  //   window.location.href = "/chooseaccount";
  // }
  // // Check for empty introUrl
  // if (!user.introUrl) {
  //   if (user.role === "rep" && path !== "/introduction") {
  //     window.location.href = "/introduction";
  //   }
  //   if (user.role === "seller" && path !== "/introductionbussiness") {
  //     window.location.href = "/introductionbussiness";
  //   }
  // }

  // // Check for niche if introUrl is provided
  // if (!user.isProfileCompleted) {
  //   if (user.role === "rep" && path !== "/repinfo") {
  //     window.location.href = "/repinfo";
  //   }
  //   if (user.role === "seller" && path !== "/bussinessinfo") {
  //     window.location.href = "/bussinessinfo";
  //   }
  // }

  return null;
}
