import axios from "axios";
import Api from "./auth";

const baseUrl = process.env.REACT_APP_API_BASE_URL;



export const Chatapi = {

  
    addRoom: async (data, token) => {
      console.log(data,"room")
      try {
        //const response = await Api.post(baseUrl + "/rooms", data);
        const response = await Api.post("/chat/rooms", data, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        return response;
      } catch (error) {
        throw error;
      }
    },
    getAllChats: async (data, token) => {
     
      try {
        //const response = await Api.post(baseUrl + "/rooms", data);
        const response = await Api.post(`/chat/rooms/getchat`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        return response;
      } catch (error) {
        throw error;
      }
    },
    getConversationByRoomId: async (data,id, token) => {
      console.log(id,"id")
     
      try {
        //const response = await Api.post(baseUrl + "/rooms", data);
        const response = await Api.post(`/chat/room/messages/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        return response;
      } catch (error) {
        throw error;
      }
    },


    Sendmessage: async (data, token) => {
      try {
        //const response = await Api.post(baseUrl + "/rooms", data);
        const response = await Api.post("/chat/message/reply", data, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        return response;
      } catch (error) {
        throw error;
      }
    },

    getUnreadMessages: async (token,id) => {
      console.log(token,"tokenenenene")
      console.log(id,"tokenenenene")
     
      try {
        //const response = await Api.post(baseUrl + "/rooms", data);
        
        const response = await Api.put(`/chat/room/read/${id}`,null, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        return response;
      } catch (error) {
        throw error;
      }
    },




  


 

  };