
import axios from "axios";
import Api from "./auth";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const api = {
  saveIntro: async (data, token) => {
    try {
      const response = await Api.post("/seller/introvideo", data, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  addInfo: async (data, token) => {
    try {
      const response = await axios.post(baseUrl+"/seller/", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  saveImage: async (data, token) => {
    try {
      const response = await Api.post("/seller/profileImage", data, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getReps: async (data, token) => {
    try {
      const response = await Api.post("/seller/reps/", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  },

  getRepsInfo: async (repId, token) => {
    try {
      const response = await Api.get( "/seller/reps/" + repId, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  addJob: async (data, token) => {
    try {
      const response = await Api.post("/seller/job", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getJobs: async (token) => {
    try {
      const response = await Api.get("/seller/job", {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getJobDetail: async (id, token) => {
    try {
      const response = await Api.get("/seller/job/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getInfo: async (token) => {
    try {
      const response = await Api.get("/seller/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  },

  addSettingInfo: async (data, token) => {
    try {
      const response = await Api.post("/seller/setting", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  support: async (token, details) => {
    try {
      const response = await Api.post(`/auth/createSupport`,
        details, {
          headers: { Authorization: `Bearer ${token}` },
        })

      return response;
    } catch (error) {
      throw error;
    }
  },

  getSellerInfo: async (data, token) => {
    try {
      const response = await axios.get(baseUrl+`/rep/getSellerInfo?id=${data}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },


};
