import Api from "./auth";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const api = {
  getintouch: async (data) => {
    try {
      const response = await Api.post("/users/getintouch", data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  requestaccess: async (data) => {
    try {
      const response = await Api.post(`/users/requestaccess`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  getUsers: async (data) => {
    try {
      const response = await Api.post(`/users`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  saveChatContent: async (data, token) => {
    try {
      const response = await Api.post("/users/chatContent", data, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });
console.log(response,"responseresponseresponseresponse")

      return response;
    } catch (error) {
      throw error;
    }
  },
};
