import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { api } from "api/auth";

const ProtectedRoutes = () => {
  

  let token = sessionStorage.getItem("refresh");

  if (!token) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet/>;
  }
};

export default ProtectedRoutes;
