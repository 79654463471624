import ProtectedRoutes from "@Components/ProtectedRoutes";
import BusinessProfileInfo from "@Pages/bussinessProfile";
import { Welcome } from "@Pages/welcome";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = React.lazy(() => import("@Pages/home"));
const Login = React.lazy(() => import("@Pages/login"));
const Signup = React.lazy(() => import("@Pages/signup"));
const Bussinessinfo = React.lazy(() => import("@Pages/bussinessinfo"));
const ChooseAccount = React.lazy(() => import("@Pages/chooseAccount"));
const Introduction = React.lazy(() => import("@Pages/introduction"));
const IntroductionBussiness = React.lazy(() =>
  import("@Pages/introductionBussiness")
);
const RepInfo = React.lazy(() => import("@Pages/repInfo"));
// const Welcome = React.lazy(() => import("@Pages/welcome"));
const WelcomeBusiness = React.lazy(() => import("@Pages/welcomeBusiness"));
const BussinessMessage = React.lazy(() => import("@Pages/bussinessMsg"));
const BussinessNotification = React.lazy(() =>
  import("@Pages/bussinessNotification")
);
const BussinessVerification = React.lazy(() =>
  import("@Pages/bussinessVerification")
);
const Funnel = React.lazy(() => import("@Pages/funnel"));
const JobInviteSend = React.lazy(() => import("@Pages/jobInviteSent"));
const JobMarket = React.lazy(() => import("@Pages/jobMarket"));
const Jobposts = React.lazy(() => import("@Pages/jobPosts"));
const Messages = React.lazy(() => import("@Pages/messages"));
const Notification = React.lazy(() => import("@Pages/notification"));
const Pricing = React.lazy(() => import("@Pages/pricing"));
const RepMarket = React.lazy(() => import("@Pages/repMarket"));
const RepProfile = React.lazy(() => import("@Pages/repPRofile"));
const Verification = React.lazy(() => import("@Pages/verification"));
const Setting = React.lazy(() => import("@Pages/setting"));
const Account = React.lazy(() => import("@Pages/account"));
const ForgotPassword = React.lazy(() => import("@Pages/forgotPassword"));
const ResetPassword = React.lazy(() => import("@Pages/resetPassword"));
// const NoMatch = React.lazy(() => import("@Pages/NoMatch"));

function Router() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="resetPassword/:token" element={<ResetPassword />} />
        <Route path="chooseaccount" element={<ChooseAccount />} />
        <Route path="introduction" element={<Introduction />} />
        <Route
          path="introductionbussiness"
          element={<IntroductionBussiness />}
        />
        <Route path="forgotPassword" element={<ForgotPassword />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="repinfo" element={<RepInfo />} />
          <Route path="bussinessinfo" element={<Bussinessinfo />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="welcomebusiness" element={<WelcomeBusiness />} />
          <Route path="bussinessChat" element={<BussinessMessage />} />
          <Route path="bussnoti" element={<BussinessNotification />} />
          <Route path="bussverifi" element={<BussinessVerification />} />
          <Route path="jobinvite" element={<JobInviteSend />} />
          <Route path="jobposts" element={<Jobposts />} />
          <Route path="jobmarket" element={<JobMarket />} />
          <Route path="messages" element={<Messages />} />
          <Route path="notification" element={<Notification />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="repmarket" element={<RepMarket />} />
          <Route path="repprofile/:repId" element={<RepProfile />} />
          <Route path="verification" element={<Verification />} />
          <Route path="setting" element={<Setting />} />
          <Route path="account" element={<Account />} />
          <Route path="sellerInfo/:id" element={<BusinessProfileInfo />} />
          

          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
        
      </Routes>
    </Suspense>
  );
}

export default Router;
