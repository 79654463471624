

export const Indicator = (props) => {
  // console.log(props?.unreadcount,"props?.unreadcount")
 
  

 

  // console.log(data?.userallchat?.unreadCount)
  return (
    <>
      <div className="badge-span"
        
      >
        {props?.unreadCount}
      </div>
    </>
  );
};
