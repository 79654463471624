import { atom } from "recoil";

// Atom to store form values
export const chatrooms = atom({
  key: "chatrooms",
  default: {
    chatrooms: [],
    selectedRoom: null,
    membersMap: {},
  },
});
