// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBR_mLiaTe9ukEe6Eq_JcQdmPiDjFLzmKY",
//   authDomain: "repconnect-2cf42.firebaseapp.com",
//   projectId: "repconnect-2cf42",
//   storageBucket: "repconnect-2cf42.appspot.com",
//   messagingSenderId: "441342422850",
//   appId: "1:441342422850:web:319a9cebe550e7f802a2db",
//   measurementId: "G-YVMBTDVH1Y",
// };

const firebaseConfig = {
  apiKey: "AIzaSyB-YXzre0gxFWEHF0BUDfIhrvfuYHoq-Bk",
  authDomain: "repconnect-c38fa.firebaseapp.com",
  projectId: "repconnect-c38fa",
  storageBucket: "repconnect-c38fa.appspot.com",
  messagingSenderId: "6951380954",
  appId: "1:6951380954:web:ba05c87be80bc0148342e3",
  measurementId: "G-Z69PDFC9CH"
};




// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);



export const requestForToken = async () => {
  try {

    const currentToken = await getToken(messaging, {
      vapidKey: "BCs6vxw7PsticWu_RUJ2ng4SQYqGwCNWwH3kw2RvsQVIefAmh_J6zCpukpKoGEBBcsRMcdms0GSXU1qB9UuyDJE",
    });
    console.log(currentToken,"dfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdfdf")
    sessionStorage.setItem('firebase',currentToken)
    if (currentToken) {
      console.log("Token received:", currentToken);
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};
