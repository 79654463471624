import { useNavigate } from "react-router-dom";

export function Welcome() {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate("/introduction");
  };

  return (
    <div>
      <title>Rep Connect</title>
      <div className="bg-black vh-103">
        <div className="bg-pink1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center mt-100">
                <h1 className="fs-48 text-white fw-700">Welcome! Let me onboard you!</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <iframe
                width="100%"
                height="500px"
                src="https://repconnect.s3.us-east-2.amazonaws.com/welcome/RC+Onboarding+(Reps).mp4"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
          <div className="row mt-60 mt-31">
            <div className="col-lg-12 text-center">
              <button onClick={handleSubmit} type="submit" className="btn btn-danger center fs-24 sm-w-100">
                Next Step
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
