import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
// console.log(baseUrl,"baseUrlbaseUrlbaseUrl");/

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://192.168.0.88:4022/v1/admin",
});

Api.interceptors.response.use(
  (response) => {
    console.log(response, "interceptor response");
    if (response?.data?.status === 403) {
      setTimeout((x) => {
        window.location.href = "/login";
      }, 3000);

      sessionStorage.clear();

      toast.error(response?.data?.message);
    }
    // toast.error("You are banned by admin");
    return response;
  },

  (error) => {
    console.log(error, "interceptor");
    if (error?.response?.status === 403 || 401) {
      setTimeout((x) => {
        window.location.href = "/login";
      }, 3000);

      sessionStorage.clear();

      toast.error(error?.response?.data?.message);
    }
    // toast.error("You are banned by admin");
    return error.response;
  }
);

export default Api;

export const api = {
  signup: async (data) => {
    try {
      const response = await axios.post(baseUrl + "/auth/register", data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  login: async (data) => {
    try {
      const response = await axios.post(baseUrl + "/auth/login", data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  addRole: async (id, data) => {
    try {
      const response = await axios.post(baseUrl + `/auth/${id}/addRole`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  fetchAccessToken: async (data) => {
    try {
      const response = await Api.post(`/auth/refresh-tokens`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },

  getUserData: async (data) => {
    try {
      const response = await Api.post(`/auth/me`, data, {
        headers: { Authorization: `Bearer ${data.accessToken}` },
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  requestPasswordReset: async (data) => {
    try {
      const response = await Api.post(`/auth/forgot-password`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (token, password) => {
    try {
      const response = await Api.post(
        `/auth/reset-password`,
        { password },
        {
          params: { token },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },
};
